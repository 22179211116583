<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="searchtime" style="margin-right: .4rem">
        <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('lang.to')"
          :start-placeholder="$t('lang.Startdate')"
          :end-placeholder="$t('lang.Enddate')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="picker"
        >
        </el-date-picker>
      </div>
      <div class="select_b" style="margin-right: .2rem">
        <span style="color: #bbbbbb">{{ $t("lang.Logtype") }}：</span>
        <el-select
          style="height: .4rem; width: 1.3rem"
          v-model="value1"
          :placeholder="$t('lang.Please')"
          popper-class="select_a"
          @change="stauts"
        >
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.Operationlog')" :value="1"></el-option>
          <el-option :label="$t('lang.systemlog')" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query">
        <div class="inputname">
          <input
            v-model="input"
            :placeholder="$t('lang.Pleaseenterthedevicename')"
          />
        </div>
        <div class="searchbtn">
          <button @click="sear">
            <p>{{ $t("lang.search") }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="name"
          :label="$t('lang.equipmentName')"
          align="center">
        </el-table-column>
		<el-table-column prop="deviceMid" :label="$t('lang.fileSize')" align="center" width="250">
			<template #default="scope">
			  <span>{{scope.row.deviceMid |mids}}</span>
			</template>
		</el-table-column>
        <el-table-column :label="$t('lang.Logtype')" align="center">
          <template #default="scope">
            <span v-if="scope.row.type == 1">
              {{$t("lang.Operationlog")}}
            </span>
            <span v-else>
              {{ $t("lang.systemlog") }}
            </span>
          </template>
        </el-table-column>
		<el-table-column prop="email" :label="$t('lang.userid')" align="center" width="300"></el-table-column>
        <el-table-column
          prop="time"
          :label="$t('lang.Latestlogtime')"
          align="center"
					 width="250"
        >
        </el-table-column>
        <el-table-column  :label="$t('lang.describe')" align="center">
					<template #default="scope">
						<span>{{scope.row.desc |text}}</span>
					</template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="150"
        >
          <template #default="scope">
            <el-button
            size="small"
              class="buttons"
              style="background-color: #1061c5"
              @click="detailsmsg(scope.row)"
              >{{ $t("lang.details") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right" class="paginat" v-if="tableData.list && tableData.list.length > 0">
        <div class="total"><span>{{$t("lang.Total")}}</span> {{tableData.total}} <span>{{$t("lang.Strip")}}</span></div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情模态框 -->
    <el-dialog
      :title="$t('lang.Logdetails')"
      :visible.sync="showSee"
      :show-close="false"
      width="9rem"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div class="details">
        <div class="binding" v-for="(item, index) in description" :key="index">
          <div>
            <label>{{ $t("lang.Operator") }}：</label>
            <span>{{ item[0] }}</span>
          </div>
          <div style="margin: 0 .3rem">
            <label>{{ $t("lang.Operationtime") }}：</label>
            <span>{{ item[1] }}</span>
          </div>
          <div>
            <label>{{ $t("lang.pedagogicaloperation") }}：</label>
            <span>{{ item[2] |text }}</span>
          </div>
        </div>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as pment from "@/api/equipment.js";
export default {
  data() {
    return {
      time: "",
      value1: "",
      input: "",
      parsm: {
        page: 1,
        size: 10,
        name: "",
        type: "",
        startTime: "",
        stopTime: "",
        email: "",
      },
      tableData: [],
      showSee: false,
      description: [],
    };
  },
  mounted() {
    let adminemail = sessionStorage.getItem("user");
    if (adminemail) {
      this.parsm.email = JSON.parse(adminemail).email;
    }
    this.getList();
  },
	filters:{
		mids(val){
			let sk=''
			if(val){
				sk=val.slice(16)
			}
			return sk
		},
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val.split(',')
				if(zhon=='zhong'){
					text=sk[0]
				}else{
					text=sk[1]
				}
			}
			return text
		}
	},
  methods: {
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取设备日志列表
    async getList() {
      let res = await pment.journallist(this.parsm);
      if (res) {
        this.tableData = res.data;
      }

      console.log(res);
    },
    //时间筛选
    picker(e) {
      if (e) {
        this.parsm.startTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.startTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //类型筛选
    stauts(e) {
      this.parsm.type = e;
      this.getList();
    },
    //名称筛选
    sear() {
      this.parsm.name = this.input;
      this.getList();
    },
    //日志详情
    async detailsmsg(row) {
      this.showSee = true;
      let res = await pment.journaldetails({
        mid: row.deviceMid,
        email: this.parsm.email,
      });
      if (res.data) {
        this.description = res.data;
      }
      console.log(res);
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
	min-height: calc(100vh - 1.35rem);
	background: #222628;
	border-radius: 0.02rem;
	padding: .3rem;
	box-sizing: border-box;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 4px;
  font-size: .16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.button_a {
  border: none;
  outline-style: none;
  margin-top: .2rem;
  width: .92rem;
  height: .46rem;
  background: #ededed;
  border: 1px solid #438749;
  border-radius: .1rem;

  p {
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: .35rem;
    text-align: center;
  }
}
.details {
  max-height: 2.5rem;
  overflow: auto;
}
.binding {
  display: flex;
  margin-bottom: .2rem;
  font-size: .15rem;
  color: #606266;
  label {
    width: 1rem;
  }
  span {
    color: #000000;
  }
}
/deep/.paginat {
  display: flex;
  .total{
    width: auto;
    height: .42rem !important;
    line-height: .42rem;
    padding: 0px .15rem;
    margin-top: 2px;
    box-sizing: border-box;
    background: #222628;
    border: 1px solid #464748;
    border-radius: 2px;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #B2B2B2;
  }
  .el-pagination span{
    width: auto;
    padding: .06rem .05rem;
    line-height: .28rem;
  }
}
/deep/.el-date-editor{
  .el-range__icon{
    line-height: .28rem;
  }
  .el-range-input{
    font-size: .14rem;
  }
  .el-range-separator{
    line-height: .27rem;
    font-size: .14rem;
  }
  .el-range__close-icon{
    font-size: .14rem;
    width: .25rem;
    line-height: .3rem;
  }
}
/deep/.el-select{
  .el-input__suffix-inner{
    .el-input__icon{
      font-size: .14rem;
      width: .25rem;
      line-height: .4rem;
    }
  }
}
</style>
